* {
  box-sizing: border-box;
}


body {
  margin: 0;
  background: #f3f3f3;
  overflow-y: scroll;
  font-family: Arial;
}

button {
  color:black;
}

.navbar-mobile {
  display: none;
  height: 50px;
  background: white;
  position:relative;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.1);
}
 
.header {
  background: black;
  padding: 2px;
  color: white;
  margin: 0px;
  text-align: center;
}

.sections {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6vw;
  background: white;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.1);
}

.sections button {
  letter-spacing: 1px;
  border: 0;
  font-size: 18px;
  margin-bottom: 1px;
  background: white;
  margin: 16px;
}

.sections button:hover {
  cursor: pointer;
  border-bottom: 1px solid black;
  margin-bottom: 15px;
}

/*
  width: 1125px;
  height: 675px;
  */

.fpage-image {
  width: max(700px, 67vw);
  z-index: -1000;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.2);
}

.fpage-image-mobile {
  display: none;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.2);
}

.title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  align-items: center;
  gap: 3vh;
}

.letter-container {
  color: white;
  text-transform: uppercase;
  font-size: 50px;
  cursor: default;
  display: flex;
}

.letter-container div {
  padding: min(0.5vw, 5px);
}

.letter-container div.character {
  transition: 0.2s;
}

.letter-container div.character.active {
  transform: translateY(-25%);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.button {
  padding: 10px;
  width: 140px;
  height: fit-content;
  border: 1px solid white;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: white;
  transition: 0.3s;
  cursor: pointer;
}

.button:hover {
  scale: 1.2;
}

.fimage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  width: fit-content;
  margin: auto;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  top: calc(100% + 0.5rem);
  padding: 0.75rem;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-5px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  pointer-events: none;
  z-index: 1;
}

.information-grid {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 2rem;
}

.dropdown.active > .link + .dropdown-menu {
  opacity: 1;
  /* allows pointer events when visible */
  transform: translateY(0);
  pointer-events: auto;
}

.dropdown-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dropdown-heading {
  margin-bottom: 20px;
}

a:link {
  text-decoration: none;
  color: gray;
}

a:visited {
  color: gray;
}

a:hover {
  color: black;
}

/**/

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding: 60px;
  
}

div.grid-item {
  position: relative;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.15);
  overflow: hidden;
}

div.grid-item > div.image-cover {
  position: absolute;
  inset: 0;
  margin: auto;
  opacity: 0;
  color: black;
  letter-spacing: 10px;
  text-transform: uppercase;
  font-size: 35px;
  cursor: pointer;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.grid-item > div.image-cover.active {
  opacity: 1;
  z-index: 2;
}

div.grid-item img {
  transition: 0.2s;
  width: max(375px, 29vw);
  display: block;
}

div.grid-item img.active {
  filter: blur(2px);
  scale: 1.1;
}

div.furniture-item-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 380px);
  grid-gap: 50px;
  padding: 50px 20px;
  justify-content: center;
}

div.furniture-item {
  display: flex;
  flex-direction: column;
  border: lightgray 1px solid;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.1);
  flex-basis: 0;
  flex-grow: 1;
  
}

div.item-info {
  height: 30%;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px;
  justify-content: center;
  gap:10px;
  box-sizing: content-box;

}

div.label {
  font-size: 25px;
  letter-spacing: 1px;
}

.yeti-style {
  background: white;
  margin: auto;
  margin-bottom: 0px;
  border-radius: 5px;
  border: lightgray 1px solid;
}

.yeti-style:hover,
.close-cart-button:hover,
.checkout-buttons:hover {
  color: lightgray;
  background: white;
  cursor: pointer;
}

.items-in-cart-buttons {
  display: flex;
  width: 175px;
  flex-wrap: wrap;
  font-size: 18px;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.cart-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: lightgray solid 2px;
  border-radius: 50px;
  transition: 0.2s;
  position:relative;
}

.cart-icon:hover {
  background: white;
  border: #f3f3f3 solid 2px;
  cursor: pointer;
}

.cart-items-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  right: -5px;
  bottom: -5px;
  font-size: 12px;
  padding: 2px;
  position: absolute;
  background: red;
  border-radius: 15px;
  color: white;
}

.mobile-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: auto;
  gap:5px;
}

.hamburger {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display:flex;
  align-items:center;
  justify-content:center;
  
}

.hamburger:hover {
  cursor:pointer;
  background:#f3f3f3;
  
}

.shopping-cart, .mobile-menu {
  display: flex;
  flex-direction: column;
  width: max(24vw, 400px);
  height: 100%;
  padding: 20px;
  border-left: lightgray 1px solid;
  background: white;
  gap: 20px;
  position: fixed;
  transition: 1s;
  right: -150%;
  z-index: 100;
}

.mobile-menu {
  gap: 30px;
  padding:30px;
}

.mobile-section {
  display:flex;
  justify-content:space-between;
  letter-spacing:1px;
}

.shopping-cart.active, .mobile-menu.active {
  right: 0;
}

.close-cart-button {
  background: white;
  color: gray;
  font-size: 25px;
  border: none;
}

.cart-remove-button {
  background: white;
  font-size: 15px;
  width: 30px;
  height: 30px;
  border: red 1px solid;
  color: red;
  border-radius: 2px;
  transition: 0.2s;
}

.cart-remove-button:hover {
  color: white;
  background: red;
  cursor: pointer;
}

.gray-cover {
  background: gray;
  opacity: 0.5;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
}

.checkout-buttons {
  background: white;
  border: solid 1px lightgray;
  border-radius: 5px;
  font-size: 15px;
  padding: 10px;
}

@media (max-width: 750px) {
  .fpage-image {
    display: none;
  }

  .fpage-image-mobile {
    display: block;
  }

  .sections {
    gap: 2vw;
  }

  .letter-container {
    flex-direction: column;
    font-size: 40px;
  }

  .sections button {
    font-size: min(2.5vw, 18px);
  }

  .shopping-cart {
    width:50vw;
  }
}

@media (max-width: 600px) {
  .navbar {
    display: none;
  }

  .navbar-mobile {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  .fimage-container {
    flex-direction: column;
  }

  .letter-container {
    flex-wrap: wrap;
    font-size: 6vw;
  }

  .fpage-image-mobile {
    width: max(185px, 65vw);
  }

  div.grid-item img {
    transition: 0.2s;
    width: max(185px, 65vw);
    display: block;
  }

  .shopping-cart {
    width:100vw;
  }

  .mobile-menu {
    width:100vw;
  }

  .cart-icon {
    border: none;
    transition:0s;
  }

  .cart-icon:hover {
    background: #f3f3f3;
    border:none;
  }

  div.furniture-item-container {
    grid-template-columns: repeat(auto-fill, 300px);
  }

  .cart-items-amount {
    right:0px;
    bottom:0px;
  }
  

}
